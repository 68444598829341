import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import sendMessage from 'apis/contact';
import { REGEXP } from 'consts';
import ReactInputMask from 'react-input-mask';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { AuthContext, AuthModalContext } from 'auth';

interface AgentContactFormProps {
  agentId: number;
}

const AgentContactForm: React.FC<AgentContactFormProps> = ({ agentId }) => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);
  const { register, control, errors, reset, handleSubmit } = useForm();

  const handleClick = e => {
    if (!authorized) {
      e.preventDefault();
      setAuthModalOpen(true);
    }
  };

  const submit = async form => {
    try {
      sendMessage({ ...form, agent_id: agentId, type: 'Contact Agent', source: 'Agent Profile' });
      reset();
      reset({ phone: '' }); // otherwise reset won't work on this field
      notify('Contact request submitted.');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <p className="contact-title">Write to me</p>
      <Input
        ref={register({
          required: 'Required',
          maxLength: {
            value: 50,
            message: 'Max length exceeded'
          },
          pattern: {
            value: REGEXP.NAME,
            message: 'Invalid name'
          }
        })}
        name="name"
        error={errors.name}
        placeholder="Name"
      />
      <Input
        ref={register({
          required: 'Required',
          maxLength: {
            value: 254,
            message: `Email can not exceed 254 characters`
          },
          pattern: {
            value: REGEXP.EMAIL,
            message: 'Invalid email format'
          }
        })}
        name="email"
        error={errors.email}
        placeholder="Email"
      />
      <Controller
        control={control}
        name="phone"
        defaultValue=""
        rules={{
          required: 'Required',
          validate: value => !value.includes('_') || 'Required'
        }}
        render={controllerProps => (
          <Input
            as={ReactInputMask}
            mask="+1 (999) 999-9999"
            placeholder="Phone"
            type="tel"
            error={errors.phone}
            className="contact-form__input"
            {...controllerProps}
          />
        )}
      />
      <Input
        ref={register({ required: 'Required' })}
        name="message"
        error={errors.message}
        placeholder="Your message"
        as="textarea"
      />
      <Button primary onClick={handleClick}>
        Send
      </Button>
    </StyledForm>
  );
};

export default AgentContactForm;

const StyledForm = styled.form`
  .input {
    width: 100%;
    margin-bottom: 8px;

    input {
      height: 36px;
    }

    textarea {
      height: 136px;
      resize: vertical;
    }
  }

  .button {
    height: 36px;
    width: 100%;
    margin-top: 16px;
  }
`;
