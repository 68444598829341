import { fetch, fetchWithToken } from 'apis';
import { urlEncodeBody } from 'utils';
import { mapFeaturedListings } from 'utils/mapping';

const mapReviews = (items): Review[] =>
  items.map(item => ({
    id: item.id,
    name: item.customer_name,
    createdOn: item.created_on * 1000,
    description: item.description,
    rating: item.rating
  }));

export async function getAgentById(id: number): Promise<AgentInfo> {
  const response = await fetch(`/agents/${id}`);
  const data = await response.json();

  const { agent_id: agentId, ...agent } = data;

  return {
    id: agentId,
    ...agent
  };
}

export async function getAgentReviews(agentId: number): Promise<Review[]> {
  const response = await fetch(`/broker_site/reviews/${agentId}`);
  const data = await response.json();

  return mapReviews(data);
}

export async function getAgentListings(agentId: number) {
  const response = await fetch(`/agents/${agentId}/listings`);
  const data = await response.json();

  return mapFeaturedListings(data.listings);
}

export async function submitAgentReview(values: {
  agentId: number;
  rating: number;
  description?: string;
}): Promise<Review[]> {
  const response = await fetchWithToken('/broker_site/reviews', {
    method: 'PUT',
    body: urlEncodeBody({
      user_id: values.agentId,
      rating: values.rating,
      description: values.description
    })
  });

  const data = await response.json();

  return mapReviews(data.reviews);
}
